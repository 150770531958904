:root {
  --card-flip-duration: 1.5s;
}

html {
  scroll-behavior: smooth;
  background-color: #333;
}

@font-face {
  font-family: Signature;
  src: url(./static/Notera.ttf);
}

@font-face {
  font-family: Body;
  src: url(./static/SFProDisplay-Regular.eot);
  src: url(./static/SFProDisplay-Regular.ttf) format('ttf');
  src: url(./static/SFProDisplay-Regular.woff) format('woff');
}

@font-face {
  font-family: Bold;
  src: url(./static/SFProDisplay-Semibold.eot);
  src: url(./static/SFProDisplay-Semibold.ttf) format('ttf');
  src: url(./static/SFProDisplay-Semibold.woff) format('woff');
}

.blue{
  color: #008cff;
}

a{
  color: unset;
  text-decoration: none;
}

.bold{
  font-family: Bold !important;
}

.app {
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: #333;
  color: #FFF;
  min-height: 100%;
  width: 100%;
  font-size: 18px;
}

.all{
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.confirm{
  color: rgb(38, 204, 57) !important;
}

.menu{
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 12;
  bottom: 100%;
  background-color: #00000000;
  background-size: 50%;
  transition: bottom 0s, background-color 0.8s, opacity 0.8s;
  opacity: 0;
  perspective: 700px;
}

.menu.opened{
  bottom: 0px;
  background-color: rgba(37, 37, 37, 0.945);
  opacity: 1;
}

.login-container{
  width: 400px;
  background-color: #EEE;
  display: inline-block;
  padding: 10px;
  border-radius: 0px;
}

.menu-card{
  opacity: 0;
  transition: all 0.5s;
}

.opened .menu-card{
  opacity: 1;
}

.menu-card{
  background-color: transparent;
  border-radius: 15px;
}

.menu-line{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 18px;
  z-index: 1;
}

.menu-icon{
  position: absolute;
  width: 30px;
  height: auto;
  left: 15px;
  top: 15px;
  z-index: 11;
  font-size: 30px;
  cursor: pointer;
}

.menu-icon:active{
  color: #E0E0E0;
}

.menu-text{
  margin-top: 50px;
  font-size: 16px;
  color: #202020;
}

.menu-text-title{
  font-size: 28px;
  color: #202020;
}

.code-div{
  background-color: #EEE;
  border-radius: 0px;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  margin-top: 20px;
}

.code-input, .code-div input{
  font-size: 32px;
  color: #222;
  width: 30px !important;
  display: inline-block;
  text-align: center;
  border: 0px;
  border-radius: 0px;
  border-bottom: 2px solid rgb(201, 201, 201);
  background-color: transparent;
  line-height: 150%;
  text-transform: uppercase;
}

.code-input:focus, .code-div input:focus{
  outline: none;
  border: 0px solid;
  border-bottom: 2px solid rgb(0, 153, 255);
}

.code-input:focus::placeholder {
  color: #FFFFFF00;
}

.cover-container{
  display: flex;
  position: fixed;
  width: 100%;
  height: 35%;
  overflow: hidden;
}

.cover-lateral{
  width: calc((100% - 800px)/2);
  height: 100%;
  background-color: #333333;
  z-index: 1
}

.cover{
  top: 0px;
  width: 100%;
  max-width: 800px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cover-focus{
  z-index: 12;
  opacity: 1 !important;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.picture-focus{
  z-index: 12;
  opacity: 1 !important;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.cover .edit-form{
  width: 95% !important;
  border-radius: 15px;
  top: 0px;
}

.edit-select-image-div{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.edit-slogan{
  position: absolute !important;
  top: 150px !important;
  width: 100% !important;
  max-width: 800px;
  margin: auto !important;
}

.edit-slogan.edit-form{
  border-radius: 0px;
}

.edit-slogan textarea{
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  font-size: 18px;
  resize: none;
}

textarea{
  border: 1px solid #999999;
  font-family: Body;
}

textarea:active{
  border: 1px solid rgb(0, 153, 255);
  outline: none;
}

textarea:focus{
  outline: none;
  border: 1px solid rgb(0, 153, 255);
}

.button-select-image{
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 0px solid;
  font-size: 14px;
  margin-top: 20px;
  cursor: pointer;
}

.button-select-image:hover{
  border: 2px solid #008cff;
}

.button-select-image + .button-select-image{
  margin-left: 15px;
}

.content{
  position: absolute;
  display: flex;
  justify-content: center;
  top: 35%;
  width: 100%;
  background-color: #333;
}

.profile {
  width: 100%;
  max-width: 800px;
  background-color: #202020;
}

.centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.vcentered{
  position: relative;
  display: inline-block;
  top: 50%;
  transition: all 0s;
  transform: translateY(-50%);
}

.link-copy{
  display: flex;
  width: fit-content;
  font-size: 18px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}

.link-copy-icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}

.picture {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 200px;
  border: solid 7px #202020;
  overflow: hidden;
}

.slogan{
  display: flex;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0px 10px;
  justify-content: center;
}

.slogan-margin{
  height: 20px;
}

.item{
  position: relative;
  table-layout: fixed;
  max-width: 700px;
  width: 90%;
  margin-top: 50px;
  white-space: nowrap;
}

.item tr{
  display: flex;
  width: 100%;
}

.item-icon{
  display: block;
  height: 100%;
  float: left;
  white-space: nowrap;
  font-size: 30px;
  margin-right: 25px;
  transform: translateY(-30px);
}

.item-icon svg{
  position: relative;
  top: -7px;
}

.item-content{
  position: relative;
  display: inline-block;
  width: 100%;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0px;
}

.item-name-td{
  display: inline-block;
  padding: 0px;
  padding-bottom: 10px;
}

.item-name{
  position: absolute;
  display: inline-block;
  top: -20px;
  margin-left: -10px;
  font-size: 14px;
  z-index: 3;
  text-transform: uppercase;
}

.item-label-element-container{
  position: relative;
}

.item-label-element{
  position: relative;
  border-radius: 0px;
  z-index: 2;
  transition: all 0.5s;
  transition-delay: 1s;
}

.item-label-element + .item-label-element{
  margin-top: 15px;
}

.item-label-element.editing{
  color: #ffffff;
  padding: 10px 5px;
  margin-top: 5px;
  background-color: #383838;
  z-index: 13;
  transition-delay: 0s;
}

.item-label{
  font-size: 14px;
  padding-left: 15px;
}

.item-editable{
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition-timing-function: linear;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 40%, #008cff 50%, #008cff 55%, #383838 55%, #383838 70%, #ffffff00 71%, #ffffff00 69%, rgb(255, 255, 255) 70%, rgb(255, 255, 255) 80%);
  background-size: 500% 100%;
  background-position-x: 0%;
  transition: all 1.0s;
}

table{
  border-collapse: collapse;
}

.editing .item-editable{
  background-position-x: 100%;
  transition: all 0s;
}

.deleting{
  background-color: #FF2365 !important;
  overflow: hidden;
  perspective: 100px;
  color: #FF2365 !important;
  transform: rotateX(90deg);
  transition: all 0.5s, background-color 0.7s;
  transition-delay: 1s !important;
  transition-timing-function: cubic-bezier(0.35, -0.32, 0.58, 1);
}

.deleting .item-editable{
  background-image: linear-gradient(90deg, #FF2365 20%, #FFF 50%);
  background-position-x: 0%;
  transition-delay: 1s !important;
  transition: all 0.5s;
}

.edit-delete-circle{
  border-radius: 100px !important;
  padding: unset !important;
  width: 25px !important;
  height: 25px !important;
}

.edit-action-centered{
  display: flex;
  justify-content: center !important;
}

.edit-form-full{
  top: 0px;
  position: absolute !important;
  height: 0px;
}

.edit-form-full.editing{
  top: 0px;
  height: 100%;
  padding: 0px !important;
}

.edit-form-full .edit-form-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 100%;
}

.swiper-content{
  text-align: center;
}

.social-icon{
  width: 68px;
  height: auto;
  font-size: 68px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social-margin{
  height: 20px;
}

.edit-social{
  top: -200px !important;
}

.delete-social{
  right: 0px !important;
}

.button-primary{
  width: 100%;
  margin-top: 20px;
  background-color: #008cff;
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  border: 0px solid;
  padding: 10px;
  border-radius: 0px;
  cursor: pointer;
  display: inline-block;
}

.button-primary:disabled{
  background-color: #adbac5;
}

.button-primary:hover{
  background-color: #166fe5;
}

.button-primary:active:hover{
  background-color: #37a5ff;
}

.button-primary:disabled:hover{
  background-color: #adbac5;
  cursor: default;
}

.button-cancel{
  width: 100%;
  margin-top: 20px;
  background-color: transparent;
  color: #e60b16;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border: 2px solid #e60b16;
  padding: 10px;
  border-radius: 0px;
  cursor: pointer;
  display: inline-block;
}

.button-cancel:disabled{
  cursor: default;
}

.button-cancel:hover{
  border-color: rgb(238, 95, 102);
  color: rgb(238, 95, 102);
  cursor: pointer;
}

.button-cancel:disabled{
  cursor: default;
  border-color: rgb(207, 160, 162);
  color: rgb(207, 160, 162);
  background-color: rgba(184, 99, 104, 0.164);
}

.button-cancel:disabled:hover{
  cursor: default;
}

.button-cancel:disabled:active{
  border-color: rgb(207, 160, 162);
  color: rgb(207, 160, 162);
  background-color: rgba(184, 99, 104, 0.164);
}

.button-cancel:active{
  background-color: rgb(240, 221, 221);
}

.button-light{
  display: inline-block;
  background-color: #e6e6e6aa;
  color: rgb(36, 36, 36);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border: 0px solid;
  padding: 5px 7px;
  border-radius: 0px;
  cursor: pointer;
}

.button-light:disabled{
  background-color: #a7a7a7dc;
}

.button-light:hover{
  background-color: #e6e6e6d0;
}

.button-light:active:hover{
  background-color: #e6e6e6d0;
}

.button-light:disabled:hover{
  background-color: #a7a7a7dc;
  cursor: default;
}

.button-confirm{
  width: 100%;
  margin-top: 20px;
  background-color: rgb(184, 255, 193);
  color: rgb(38, 204, 57);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border: 2px solid rgb(38, 204, 57);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
}

.margin-button{
  margin-top: 10px;
}

.void-button{
  background-color: #00000000 !important;
  border: 0px solid !important;
  padding: 0px !important;
  font-size: 22px !important;
  cursor: pointer;
}

.item-element + .item-element{
  margin-top: 3px;
}

.edit-social .void-button{
  font-size: 16px !important;
}

.menu .void-button{
  font-size: 16px !important;
}

.gallery{
  position: relative;
  width: 100%;
}

.gallery-album{
  width: 100%;
  max-width: unset;
  margin-top: 100px;
}

.gallery-img{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.gallery-nophoto{
  width: 100%;
}

.gallery-title{
  position: absolute;
  width: 100%;
  bottom: 20px;
  text-align: center;
  padding: 10px 0px;
  text-transform: uppercase;
  background-color: #20202075;
  z-index: 10;
}

.gallery-delete-div{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;
  margin-bottom: 20px;
}

.gallery-delete{
  position: absolute !important;
  height: 32px !important;
  width: 32px !important;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  top: 10px;
  left: 10px;
  background-color: #ffffff !important;
  color: #e60b16 !important;
  font-size: 22px;
  padding: 0px 0px !important;
  border: 0px solid !important;
  border-radius: 0px !important;
}

.swiper-addphoto{
  display: flex;
  flex-direction: column;
  justify-content: justify-center;
  height: 100%;
  cursor: pointer;
}

.hr{
  width: 80%;
  height: 1px;
  background-color: #EEE;

}

.footer{
  width: 100%;
  max-width: unset;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-table{
  position: relative;
  width: 100%;
  margin-top: 0px;
  text-align: right;
}

.footer-text{
  width: 50%;
  right: 0px;
  text-align: center;
  margin-left: auto;
  margin-right: 0;
  padding: 20px 0px;
}

.swiper-pagination-bullet{
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: #b6c3ce;
}

.swiper-pagination-bullet + .swiper-pagination-bullet{
  margin-left: 15px;
}

.swiper-pagination-bullet-active{
  background-color: #008cff;
}

.swiper-pagination-bullets{
  margin-top: 10px;
  text-align: center;
}

.card-container{
  position: relative;
  perspective: 1000px;
  width: 100%;
}

.card-ratio{
  position: relative;
  width: 100%;
  height: auto;
}

.card{
  position: absolute;
  left: 0px;
  top: 0px;
  display: inline-block;
  width: 100%;
  height: 100%;
  transition: all var(--card-flip-duration);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.432);
  transform-style: preserve-3d;
  border-radius: 15px;
}

.card.flipped{
  transform: rotateY(180deg);
  transition-timing-function: ease-out;
}

.card-face-black{
  background-color: #181818;
  color: #fff;
}

.card-front, .card-back {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  outline: 1px solid transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back{
  transform: rotateY(180deg);
}

.card-front::after{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(165, 165, 165, 0.15) 0%, rgba(255,255,255,0) 70%, rgba(255,255,255,0) 100%);
  background-size: 500% 200%;
  background-position-x: 0;
  background-position-y: 100%;
  background-repeat: no-repeat;
  border-radius: 15px;
  transition: all calc(var(--card-flip-duration) / 2);
  transition-timing-function: ease-out;
}

.card-back::after{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(165, 165, 165, 0.15) 0%, rgba(165, 165, 165, 0) 70%, rgba(255,255,255,0) 100%);
  background-size: 500% 200%;
  background-position-x: 0;
  background-position-y: 100%;
  background-repeat: no-repeat;
  border-radius: 15px;
  transition: all calc(var(--card-flip-duration) / 2);
  transition-delay: calc(var(--card-flip-duration) / 2);
  transition-timing-function: ease-out;
}

.fliped{
  transform: rotateY(180deg);
}

.flipped .card-front::after, .flipped .card-back::after{
  background-position-x: 130%;
}

.card-paint{
  position: relative;
  display: inline-block;
  height: auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition-timing-function: ease-out;
}

.card-front .card-paint{
  transition: all calc(var(--card-flip-duration) / 2);
}

.card-back .card-paint{
  transition: all calc(var(--card-flip-duration) / 2);
  transition-delay: calc(var(--card-flip-duration) / 2);
}

.flipped .card-paint{
  background-position-x: 100%;
}

.card-paint-gold{
  color: #FFF;
  font-weight: 300;
  font-size: 40px;
  background-image: linear-gradient(80deg, rgb(255, 167, 0) 0%, rgb(255, 167, 0) 25%, rgb(247, 248, 191) 25%, rgb(247, 248, 191) 65%, rgb(255, 167, 0) 65%, rgb(255, 167, 0) 70%, rgb(247, 248, 191) 70%, rgb(247, 248, 191) 75%, rgb(255, 167, 0) 75%, rgb(255, 167, 0) 100%);
  background-size: 500% 200%;
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: 0;
}



@keyframes card-code-animation {
  0% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
}

.card-code{
  display: inline-block;
  position: absolute;
  height: auto;
  bottom: 10px;
  right: 15px;
  color: #FFF;
  font-size: 18px;
  font-weight: 300;
}

.card-code-animated{
  font-weight: 500;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition-timing-function: linear;
  background-image: linear-gradient(90deg, rgba(250,23,167,1) 0%, rgba(250,23,167,1) 20%, rgba(23,190,250,1) 50%, rgba(250,23,167,1) 80%);
  background-size: 500% 100%;
  background-position-x: 0%;
  animation-name: card-code-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.signature{
  font-family: Signature;
}

.card-logo{
  width: 50%;
  height: 50%;
  mask-clip: padding-box;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-clip: unset;
}

.button-text{
  color:rgb(0, 153, 255);
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  border: 0px solid;
  cursor: pointer;
}

.edit-overlay{
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 0px;
  top: 0px;
  left: 0px;
  opacity: 0;
  background-color: #1f1f1f8c;
  transition: opacity 0.5s, height 0s;
  transition-delay: 1s;
}

.edit-overlay.editing{
  height: 100%;
  opacity: 1;
  transition-delay: 0s;
}

.edit-button{
  position: absolute;
  right: 0px;
  top: 3px;
  z-index: 3;
  background-color: #202020;
}

.edit-form{
  position: relative;
  border-radius: 0px;
  max-height: 0px;
  width: 100%;
  top: -15px;
  background-color: #FFF;
  border-radius: 0px 0px 0px 0px;
  z-index: 5;
  transition: all 1s;
  transition-timing-function: cubic-bezier(0.35, -0.32, 0.58, 1);
  transition-delay: 0s;
  overflow: hidden;
}

.edit-form.editing{
  background-color: rgb(255, 255, 255);
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  padding: 30px 0px 0px 0px;
  transition-timing-function: cubic-bezier(0.35, -0.32, 0.58, 1);
  z-index: 12;
  transition-delay: 0.2s;
}

.gallery .edit-form{
  top: -400px !important;
}

.edit-form-div{
  padding: 0px 15px;
}

.edit-form-field + .edit-form-field{
  margin-top: 15px
}

.edit-div-action{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  padding: 10px 10px;
}

.edit-confirm{
  position: relative;
  width: 25px;
  height: 25px;
  margin-left: 20px;
  border-radius: 35px;
  border: 0px solid;
  background-color: #008cff;
}

.edit-delete{
  position: relative;
  width: auto;
  height: 25px;
  padding: unset;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 0px;
  display: block;
  border-radius: 5px;
  border-width: 1px;
  background-color: #FF236533;
}

.edit-input{
  width: 100%;
  background-color: transparent;
  color: #202020;
  border: 0px solid;
  font-size: inherit;
}

.input-div{
  position: relative;
}

.input-field{
  width: 100%;
  height: auto;
  font-size: 16px;
  padding: 3px 0px;
  background-color: #FFF;
  border: 0px solid;
  outline: none;
}

.input-field:focus{
  outline: none;
  border: none;
}

.input-line{
  width: 100%;
  height: 1px;
  left: 0px;
  bottom: 0px;
  background-color: #c0c0c0;
}

.input-line::after{
  content: "";
  position: absolute;
  width: 0px;
  height: 1px;
  bottom: 0px;
  left: 0px;
  background-color: #008cff;
  transition: all 0.5s;
  transition-timing-function: ease-in;
}

.input-field:focus + .input-line::after{
  width: 100%;
}

.save-button{
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 50px;
  border-radius: 0px;
  background-color: #008cff;
  cursor: pointer;
  z-index: 10;
}

@keyframes toSaveAnimation {
  from {
          transform: scale(1);
          opacity: 1;
        }
  to {
      transform: scale(1.5);
      opacity: 0;
  }
}

.toSave::before{
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: 2px solid #008cff;
  box-sizing: border-box;
  animation-name: toSaveAnimation;
  animation-duration: 0.7s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.button-upload{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  background-color: rgb(216, 216, 216);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  color: #202020;
  font-size: 20px;
  border: 0px solid;
  border-radius: 5px;
  cursor: pointer;
}

.button-upload:hover{
  background-color: rgb(230, 230, 230);
}

.button-upload:active{
  background-color: rgb(200, 200, 200);
}

.gallery .button-upload{
  width: 100%;
  margin: auto;
}

.upload-confirm{
  font-size: 32px;
  color:#008cff;
  animation-name: confirm-animation;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0,1.45,1,1.08);
  border-radius: 100px;
  background-color: rgba(230, 230, 230, 0.7);
}

.upload-error{
  font-size: 32px;
  color: #FF2365;
  animation-name: confirm-animation;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0,1.45,1,1.08);
  border-radius: 100px;
  background-color: rgba(230, 230, 230, 0.7);
}

@keyframes confirm-animation {
  from {transform: scale(0%);}
  to {transform: scale(100%);}
}

.CircularProgressbar-path{
  stroke: #008cff !important;
}

.notfound-title{
  font-size: 10em;
  color: rgb(236, 236, 236);
}

.notfound-subtitle{
  font-size: 7em;
  color: rgb(255, 255, 255);
}

.socialPrefix{
  color: #999999;
  font-size: 13px;
  margin-right: 5px;
}

@keyframes toastAnimation {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.map{
  width: 100%;
  height: 250px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 0px;
}

.item-billing-label{
  font-size: 12px;
  text-transform: uppercase;
}

.expand-container{
  display: flex;
  width: fit-content;
  font-size: 15px;
  margin-top: 5px;
  cursor: pointer;
}

.expand-icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.placeholder{
  opacity: 0.5;
}
